<script lang="ts">
    import { Input } from '$lib/components/ui/input/index.js';
    import { Label } from '$lib/components/ui/label/index.js';
    import { Button } from '$lib/components/ui/button';
    import { Separator } from '$lib/components/ui/separator/index.js';
    import { inertia } from '@inertiajs/svelte';
    import { onMount } from 'svelte';
    import { Turnstile } from 'svelte-turnstile';
    import { getTheme } from '@/utility/theming/darkmode.ts';
    import axios from 'axios';
    import { animateStars, createStarGrid } from '@/utility/stars';
    import toast from 'svelte-french-toast';
    import AuthPageFormContainer from './AuthPageFormContainer.svelte';

    const SITE_KEY: string = '0x4AAAAAAAIbV5smWueSWBbM';
    let turnstileToken: string = '';
    let email: string = '';
    let requestingPasswordReset: boolean = false;
    let isPasswordResetLinkSent: boolean = false;

    function handleTurnstileCallback(event: CustomEvent<{ token: string }>): void {
        turnstileToken = event.detail.token;
    }

    function resetTurnstileToken(): void {
        turnstileToken = '';
    }

    const handleSubmit = async (event: Event): Promise<void> => {
        event.preventDefault();
        if (!email) {
            toast('Please enter your email address.');
            return;
        }
        if (!turnstileToken) {
            toast('Please complete the Turnstile verification.');
            return;
        }
        requestingPasswordReset = true;

        try {
            const response = await axios.post('/password/email', { email, turnstileToken });
            if (response.status === 200) {
                isPasswordResetLinkSent = true;
            } else {
                toast('An error occurred. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            toast('An error occurred. Please try again.');
        } finally {
            requestingPasswordReset = false;
        }
    };

    onMount(() => {
        const starsContainer: HTMLElement = document.getElementById('stars-container') as HTMLElement;
        createStarGrid(starsContainer);
        setInterval(() => animateStars(), 100);
    });
</script>

<AuthPageFormContainer>
    {#if isPasswordResetLinkSent}
        <div class="success-message">
            <p>Password reset link sent successfully. Please check your email.</p>
        </div>
    {:else}
        <form on:submit="{handleSubmit}">
            <div class="mb-3 flex w-full max-w-lg flex-col gap-1.5">
                <Label for="email" class="dark:text-white">Email</Label>
                <Input type="email" id="email" placeholder="email" bind:value="{email}" class="dark:text-white" />
            </div>
            <div class="my-3 h-[72px]">
                <Turnstile
                    siteKey="{SITE_KEY}"
                    theme="{getTheme()}"
                    size="flexible"
                    on:turnstile-callback="{handleTurnstileCallback}"
                    on:turnstile-expired="{resetTurnstileToken}"
                    on:turnstile-timeout="{resetTurnstileToken}"
                    on:turnstile-error="{resetTurnstileToken}"
                    class="mb-3"
                />
                <!-- <div class="z-10 size-full animate-pulse rounded-lg bg-gray-300 dark:bg-white/10"></div> -->
            </div>

            <div id="turnstile-widget"></div>
            <Button
                class="mb-3 w-full text-white"
                type="submit"
                disabled="{requestingPasswordReset || !email || !turnstileToken}"
            >
                {requestingPasswordReset ? 'Sending...' : 'Send Password Reset Link'}
            </Button>
            <Separator class="my-3 bg-neutral-200 dark:bg-neutral-800" />
            <p class="text-center text-sm text-muted-foreground dark:text-white">
                Remembered the password?
                <a
                    use:inertia
                    href="/login"
                    class=" text-gradient font-weight-bold bg-gradient-to-r from-bmn-400 to-bmn-500 hover:!from-bmn-400 hover:!to-bmn-700"
                >
                    Back to Login
                </a>
            </p>
        </form>
    {/if}
</AuthPageFormContainer>
